(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

var _Slider = require("./modules/Slider");

var slider = Object.create(_Slider.HorizontalSlider).initialize(document.querySelector("#slider"), document.querySelector("#next-btn"), document.querySelector("#prev-btn"));

},{"./modules/Slider":2}],2:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VerticalSlider = exports.HorizontalSlider = void 0;
var Slider = {
  current: 0,
  slider: null,
  nextBtn: null,
  prevBtn: null,
  direction: null,
  init: function init(sliderElement, nextBtn, prevBtn, sliderDirection) {
    var _this = this;

    this.direction = sliderDirection || "left";

    if (sliderElement && sliderElement.children.length > 0) {
      this.slider = sliderElement;
      this.slides().forEach(function (slideItem, index) {
        if (index === _this.current) {
          slideItem.style.opacity = 1;
        } else {
          slideItem.style.opacity = 0;
        }

        slideItem.style[sliderDirection] = "".concat(index * 100, "%");
      });
    }

    if (nextBtn && prevBtn) {
      this.nextBtn = nextBtn;
      this.prevBtn = prevBtn;
      this.updateControls();
      nextBtn.addEventListener("click", function () {
        _this.next();
      });
      prevBtn.addEventListener("click", function () {
        _this.prev();
      });
    }
  },
  next: function next() {
    var _this2 = this;

    if (this.current < this.slides().length - 1) {
      this.current += 1;
      this.updateControls();
      this.slides().forEach(function (slideItem, index) {
        _this2.show(slideItem, index);

        _this2.shift(slideItem, -100);
      });
    }
  },
  prev: function prev() {
    var _this3 = this;

    if (this.current > 0) {
      this.current -= 1;
      this.updateControls();
      this.slides().forEach(function (slideItem, index) {
        _this3.show(slideItem, index);

        _this3.shift(slideItem, 100);
      });
    }
  },
  show: function show(slideItem, index) {
    if (index === this.current) {
      slideItem.style.opacity = 1;
    } else {
      slideItem.style.opacity = 0;
    }
  },
  shift: function shift(slide, amount) {
    var currentPosition = parseInt(slide.style[this.direction], 10);
    var newPosition = currentPosition + amount;
    slide.style[this.direction] = "".concat(newPosition, "%");
  },
  slides: function slides() {
    return this.slider ? Array.from(this.slider.children) : [];
  },
  updateControls: function updateControls() {
    var first = 0;
    var last = this.slides().length - 1;

    switch (this.current) {
      case first:
        this.prevBtn.classList.add("disabled");
        this.nextBtn.classList.remove("disabled");
        break;

      case last:
        this.prevBtn.classList.remove("disabled");
        this.nextBtn.classList.add("disabled");
        break;

      default:
        this.prevBtn.classList.remove("disabled");
        this.nextBtn.classList.remove("disabled");
        break;
    }
  }
};
var HorizontalSlider = Object.create(Slider);
exports.HorizontalSlider = HorizontalSlider;

HorizontalSlider.initialize = function (sliderElement, nextBtn, prevBtn) {
  this.init(sliderElement, nextBtn, prevBtn, "left");
};

var VerticalSlider = Object.create(Slider);
exports.VerticalSlider = VerticalSlider;

VerticalSlider.initialize = function (sliderElement, nextBtn, prevBtn) {
  this.init(sliderElement, nextBtn, prevBtn, "top");
};

},{}]},{},[1]);
